import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import NewsCard from "../components/news-card";

const News = ({ data: { hero, news } }) => {

return (

<Layout hero={hero}>
    <div className="container mx-auto mb-16">
        <div className="sm:flex flex-wrap">
            {news.edges.map(({node}) => (
                <NewsCard key={node.id} node={node}/>
            ))}
        </div>
    </div>
</Layout>

)};

export const query = graphql`
    query allNews {
       hero: contentfulNewsPage {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize (width: 1440) {
                    src 
                }
            }
       }

      news:allContentfulNews (sort: {order: DESC, fields: date}) {
                edges {
                  node {
                    id
                    slug
                    title
                    date (formatString: "MMMM DD, YYYY")
                    description
                    picture {
                       fluid (maxWidth:800, quality:60) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                }
             }
          }
   }
`;


export default News;
